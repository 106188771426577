import set from "lodash/set";

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import '../utils/polyfills';

set(
  App,
  "Navigation",
  (function () {
    const PROFILE_NAVIGATION_CLASS = "js-ProfileNavigation";

    function injectNavigation() {
      var profileNavigation = document.getElementsByClassName(PROFILE_NAVIGATION_CLASS);

      fetch("/v3/_includes/navigation")
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch navigation.');
          }

          return response.text()
        })
        .then(data => {
          for (var i = 0; i < profileNavigation.length; i++) {
            profileNavigation[i].innerHTML += data;
          }
        });
    }

    return {
      init: function () {
        injectNavigation();
      },
    };
  })()
);

App.Navigation.init();
