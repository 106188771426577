import documentReady from '../utils/document-ready';
import debounce from "lodash/debounce";


export class DynamicMenu {
    constructor(root) {
        this.root = root;

        this.menuItems = this.root.querySelectorAll('li:not([is="dynamic-menu-more"])')
        this.more = this.root.querySelector('[is="dynamic-menu-more"]');
        this.moreMenu = this.more.querySelector('ul');

        this.updateMenu();

        window.addEventListener('resize', debounce(this.updateMenu.bind(this), 100));
    }

    hasHorizontalScrollbar(el) {
        return el.scrollWidth > el.clientWidth;
    }

    moveAllToMenu() {
        for (let i = this.menuItems.length - 1; i >= 0; i--) {
            const item = this.menuItems[i];
            this.moveToMenu(item);
        }
    }

    moveToDropdown(item) {
        const detachedItem = item.parentElement.removeChild(item);
        const detachedItemLink = detachedItem.querySelector('a');
        detachedItem.classList.remove('nav-item');
        detachedItemLink.classList.remove('nav-link');
        detachedItemLink.classList.add('dropdown-item');

        this.moreMenu.prepend(detachedItem);
    }

    moveToMenu(item) {
        const detachedItem = item.parentElement.removeChild(item);
        const detachedItemLink = detachedItem.querySelector('a');
        detachedItem.classList.add('nav-item');
        detachedItemLink.classList.add('nav-link');
        detachedItemLink.classList.remove('dropdown-item');

        this.root.prepend(detachedItem);
    }

    updateMenu() {
        this.moveAllToMenu();

        for (let i = this.menuItems.length - 1; i >= 0; i--) {
            const item = this.menuItems[i];
            if (this.hasHorizontalScrollbar(this.root)) {
                this.moveToDropdown(item);
            }
        }

        if (this.moreMenu.children.length > 0) {
            this.more.classList.remove('invisible');
            this.root.classList.add('overflow-visible');
        } else {
            this.more.classList.add('invisible');
            this.root.classList.remove('overflow-visible');
        }
    }
}

export const menus = [];
documentReady(() => {
    document.querySelectorAll('[is="dynamic-menu"]').forEach(root => {
        menus.push(new DynamicMenu(root));
    });
})
